/* eslint-disable radix */
/* eslint-disable camelcase */
export const constructGroups = response => {
  if (!response) return [];

  response.forEach((data, index) => {
    const { reattempts_timeouts } = data;

    const reattemptsArray = [];
    const reattempts = [];

    let currentTimeout = 0;

    reattemptsArray[0] = [{ hours: 0, minutes: 0 }];
    reattempts_timeouts.forEach((timeout) => {
      currentTimeout += timeout;

      const days = parseInt(currentTimeout / (60 * 24));
      const hours = parseInt((currentTimeout % (60 * 24)) / 60);
      const minutes = parseInt((currentTimeout % (60 * 24)) % 60);

      if (!reattemptsArray[days]) {
        reattemptsArray[days] = [];
      }
      reattemptsArray[days].push({ hours, minutes });
    });

    reattemptsArray.forEach((reattempt, indexReattempt) => {
      reattempts.push({ day: indexReattempt, attempts: reattempt });
    });

    response[index].reattempts = reattempts;
  });

  return response;
};

export const constructGroupsToRequest = data => {
  if (!data) return {};

  const {
    id,
    name,
    timezone_id,
    location_id,
    calls_paused,
    call_distribution_type,
    reattempts_disabled,
    reattempts_timeouts,
    reattempts,
    park_lead_timeout,
    concurrent_calls,
    reps,
    working_hours,
    schedule,
    allSelected,
    color,
    backup_rep_id,
  } = data;

  const workingHours = JSON.parse(JSON.stringify(working_hours));

  const scheduleNew = JSON.parse(JSON.stringify(schedule));

  const reattemptsArray = [];

  let prevTime = 0;

  if (reattempts) {
    reattempts.forEach((reattempt) => {
      reattempt.attempts.forEach((attempt) => {
        const time = reattempt.day * 24 * 60 + attempt.hours * 60 + attempt.minutes;

        if (time !== 0) {
          reattemptsArray.push(time - prevTime);

          prevTime = time;
        }
      });
    });
  }

  if (allSelected) {
    Object.entries(workingHours).forEach(([day]) => {
      if (allSelected !== day && workingHours[day].is_open == true) {
        workingHours[day] = workingHours[allSelected];
      }
    });

    Object.entries(scheduleNew).forEach(([day]) => {
      if (allSelected !== day && workingHours[day].is_open == true) {
        scheduleNew[day] = scheduleNew[allSelected];
      }
    });
  }

  return {
    id,
    name,
    timezone_id,
    location_id,
    calls_paused,
    call_distribution_type,
    reattempts_disabled,
    reattempts_timeouts: reattempts ? reattemptsArray : reattempts_timeouts,
    park_lead_timeout,
    concurrent_calls,
    reps,
    working_hours: workingHours,
    schedule: scheduleNew,
    allSelected,
    color,
    backup_rep_id,
  };
};

export const constructGroup = response => {
  if (!response) {
    return {};
  }

  const { data } = response;
  const { reattempts_timeouts } = data;

  const reattemptsArray = [];
  const reattempts = [];

  let currentTimeout = 0;

  reattemptsArray[0] = [{ hours: 0, minutes: 0 }];
  reattempts_timeouts.forEach((timeout) => {
    currentTimeout += timeout;

    const days = parseInt(currentTimeout / (60 * 24));
    const hours = parseInt((currentTimeout % (60 * 24)) / 60);
    const minutes = parseInt((currentTimeout % (60 * 24)) % 60);

    if (!reattemptsArray[days]) {
      reattemptsArray[days] = [];
    }
    reattemptsArray[days].push({ hours, minutes });
  });

  reattemptsArray.forEach((reattempt, indexReattempt) => {
    reattempts.push({ day: indexReattempt, attempts: reattempt });
  });

  response.data = {
    ...data,
    reattempts,
  }

  return response;
};

export const constructGroupTemplate = data => {
  if (!data) return {};

  return data;
};

export const constructGroupImported = data => {
  if (!data) return {};

  return data;
};

export const constructGroupExported = data => {
  if (!data) return {};

  return data;
};
